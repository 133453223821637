<template>
  <div>Cargando datos...
    <h1>{{this.$route.params.url}}</h1>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-2.scss";
</style>

<script>
import Vue from "vue";
import Swal from "sweetalert2";

export default {
  name: "app-login-router",
  props: {
    email: {
      type: String,
    },
    password: {
      type: String,
    },
    url: {
      type: String,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
    this.doLogin();
  },
  methods: {
    doLogin: function() {
      var model = {
        usuario: this.$route.params.email,
        password: this.$route.params.password,
      };
      this.$http.post(this.apiRoute("Account", "Login"), model).then(
        function(response) {
          if (response.body.error) {
            Swal.fire({
              icon: "error",
              title: "",
              text: response.body.message,
            });
          } else if (!response.body.result) {
            Swal.fire({
              icon: "info",
              title: "",
              text: response.body.message,
            });
          } else if (response.body.data.esTemporal) {
            Swal.fire({
              icon: "success",
              title: "Cambio de contraseña",
              text: response.body.message,
            });
          } else {
            this.$session.start();

            //this.$session.set("Origin", 'APP');
            this.$session.set("UserName", response.body.data.userName);
            this.$session.set("UsuarioId", response.body.data.usuarioId);
            this.$session.set("RolId", response.body.data.rolId);
            this.$session.set("CodigoRol", response.body.data.codigoRol);
            this.$session.set("RolNombre", response.body.data.rolNombre);
            this.$session.set("Nombres", response.body.data.nombres);
            this.$session.set(
              "ApellidoPaterno",
              response.body.data.apellidoPaterno
            );
            this.$session.set(
              "ApellidoMaterno",
              response.body.data.apellidoMaterno
            );
            this.$session.set(
              "CorreoElectronico",
              response.body.data.correoElectronico
            );

            this.$session.set("Celular", response.body.data.celular);
            this.$session.set(
              "TipoDocumento",
              response.body.data.tipoDocumento
            );
            this.$session.set(
              "NumeroDocumento",
              response.body.data.numeroDocumento
            );
            this.$session.set("RutaFoto", response.body.data.rutaFoto);

            this.$session.set(
              "ProcesoElectoralId",
              response.body.data.procesoElectoralActualId
            );
            this.$session.set(
              "ProcesoElectoral",
              response.body.data.procesoElectoralActual
            );

            this.$session.set("token", response.body.data.token);
            this.$session.set("jwt", response.body.data.token);

            Vue.http.headers.common["Authorization"] =
              "Bearer " + response.body.data.token;

            //Vue.prototype.startSignalR(response.body.data.token);
            //this.$router.push({ name: "home-dashboard-user" });
            //this.$router.push({ name: "sheet-local-edit-app" });
           // window.location = 'https://google.com'

            this.$router.push({
                                  name: "sheet-local-edit-app",
                                  params: {
                                    procesoElectoralId: parseInt(this.$route.params.url1) ,
                                    localVotacionId: parseInt(this.$route.params.url2) ,
                                  },
                                });
            //window.location = "http://localhost:8081/sheet/local/editApp/" +  this.$route.params.url1 + "/" +  this.$route.params.url2;
          }
        },
        function(error) {
          Swal.fire("", this.defaultErrorMessage, "error");
        }
      );
    },
  },
};
</script>
